<template>
  <NuxtLink class="work-card" :class="work.slug" :to="`/work/${work.slug}`">
    <div class="aspect-[3/4]">
      <img class="h-full w-full object-cover" :src="`${baseURL}${work.cover_image.url}`" :alt="work.name" />
    </div>
    <p class="p-medium medium uppercase mt-3 hidden md:block">
      {{ work.name }}
    </p>
  </NuxtLink>
</template>

<script>
export default {
  name: "WorkCard",
  props: {
    work: Object,
  },
};
</script>
<script setup>
const config = useRuntimeConfig();
const baseURL = config.public["baseURL"];
</script>
